import React from 'react';
import AlphabetPlayer from './components/AlphabetPlayer';
import './App.css';

function App() {
  return (
    <div className="App">
      <AlphabetPlayer />
    </div>
  );
}

export default App;
