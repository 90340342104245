import React, { useState, useRef, useEffect } from 'react';

const letters = Array.from({ length: 26 }, (_, i) => ({
  id: i + 1,
  letter: String.fromCharCode(65 + i),
  name: `${String.fromCharCode(65 + i)} the ${[
    'Alligator', 'Bear', 'Caterpillar', 'Dinosaur', 'Elephant',
    'Flamingo', 'Goose', 'Horse', 'Insect', 'Jellyfish',
    'Kangaroo', 'Lizard', 'Moose', 'Newt', 'Ostrich',
    'Pelican', 'Queen Bee', 'Rhino', 'Snake', 'Tiger',
    'Pup', 'Vulture', 'Worm', 'Fox', 'Yak', 'Zebra'
  ][i]}`
}));

const AlphabetPlayer = () => {
  const [currentLetter, setCurrentLetter] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(1);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', () => setIsPlaying(false));
    }
  }, []);

  const handlePlay = (letterId) => {
    const audio = audioRef.current;
    if (!letterId) {
      letterId = 1; // Default to A if no letter is selected
    }

    if (currentLetter === letterId && isPlaying) {
      audio.pause();
      setIsPlaying(false);
    } else {
      const mp3Src = `/mp3s/${letterId}.mp3`;
      audio.src = mp3Src;
      audio.play();
      setCurrentLetter(letterId);
      setIsPlaying(true);
    }
  };

  const handleSkip = (direction) => {
    let newIndex;
    if (!currentLetter) {
      newIndex = direction === -1 ? 26 : 1; // Default to Z if skipping back, A if skipping forward
    } else {
      newIndex = (currentLetter + direction + 26 - 1) % 26 + 1;
    }
    handlePlay(newIndex);
  };

  const handleTimeUpdate = () => {
    const audio = audioRef.current;
    const progressValue = (audio.currentTime / audio.duration) * 100;
    setProgress(progressValue);
  };

  const handleProgressChange = (e) => {
    const audio = audioRef.current;
    const newTime = (e.target.value / 100) * audio.duration;
    audio.currentTime = newTime;
    setProgress(e.target.value);
  };

  const handleVolumeChange = (e) => {
    const volumeValue = e.target.value;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  };

  return (
    <div className="alphabet-player">
      <div className="amazing-action-title">
        {"Amazing".split("").map((char, index) => (
          <img
            key={index}
            src={`/images/${char.toUpperCase().charCodeAt(0) - 65 + 1}.png`}
            alt={char}
            style={{ width: '24px', height: '24px' }}
          />
        ))}
        <span>&nbsp;&nbsp;</span>
        {"Action".split("").map((char, index) => (
          <img
            key={index}
            src={`/images/${char.toUpperCase().charCodeAt(0) - 65 + 1}.png`}
            alt={char}
            style={{ width: '24px', height: '24px' }}
          />
        ))}
        <span>&nbsp;&nbsp;</span>
        {"Alphabet".split("").map((char, index) => (
          <img
            key={index}
            src={`/images/${char.toUpperCase().charCodeAt(0) - 65 + 1}.png`}
            alt={char}
            style={{ width: '24px', height: '24px' }}
          />
        ))}
      </div>

      <div className="background-animation"></div>

      <div className="buttons">
        {/* First rows with all letters */}
        {letters.map(({ id, letter, name }) => (
          id < 25 ? (
            <button
              key={id}
              onClick={() => handlePlay(id)}
              className={`letter-button ${currentLetter === id ? 'selected' : ''}`}
            >
              <img src={`/images/${id}.png`} alt={`Letter ${letter}`} width="96" height="96" />
            </button>
          ) : null
        ))}

        {/* Last row for Y and Z with invisible squares */}
        <button className="invisible-button"></button>
        <button className="invisible-button"></button>
        <button
          onClick={() => handlePlay(25)}
          className={`letter-button ${currentLetter === 25 ? 'selected' : ''}`}
        >
          <img src={`/images/25.png`} alt="Y the Yak" width="96" height="96" />
        </button>
        <button
          onClick={() => handlePlay(26)}
          className={`letter-button ${currentLetter === 26 ? 'selected' : ''}`}
        >
          <img src={`/images/26.png`} alt="Z the Zebra" width="96" height="96" />
        </button>
        <button className="invisible-button"></button>
        <button className="invisible-button"></button>
      </div>

      <audio ref={audioRef} onTimeUpdate={handleTimeUpdate} />

      {currentLetter && (
        <div className="song-info">
          <h3>{letters[currentLetter - 1].name}</h3>
        </div>
      )}

      <div className="controls modern-controls">
        <button className="control-button" onClick={() => handleSkip(-1)}>⏮️</button>
        <button className="control-button play-pause" onClick={() => handlePlay(currentLetter)}>{isPlaying ? '⏸️' : '▶️'}</button>
        <button className="control-button" onClick={() => handleSkip(1)}>⏭️</button>
        <input
          type="range"
          value={progress}
          onChange={handleProgressChange} /* Make progress bar interactable */
          className="progress-bar"
        />
        <div className="volume-control">
          <input
            type="range"
            value={volume}
            onChange={handleVolumeChange}
            min="0"
            max="1"
            step="0.01"
          />
          <label>🔊</label>
        </div>
      </div>
    </div>
  );
};

export default AlphabetPlayer;
